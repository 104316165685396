import React from 'react';

import { FormattedMessage } from '../../../../util/reactIntl';

import { Button } from '../../../../components';
import css from '../../DashboardPage.module.css';
import { createResourceLocatorString } from '../../../../util/routes';
import { getUserStatus } from '../../../../util/user';
import Image from '../../images/user_chair_image.png'
import { accountTypes } from '../../../../util/accountTypes';

const DashboardConsultant = (props) => {

    const { history, routeConfiguration, currentUser, isPendingVerification } = props;

    return (
        <div className={css.roundedContainer}>


            <div className={css.paddedContainer}>
                <img className={css.mainImage} src={Image} />

                <p className={css.info}>
                    <FormattedMessage id="DashboardConsultant.info" />
                </p>


                <Button
                    className={css.button}
                    onClick={e => {
                        e.preventDefault();
                        history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, { pub_ownerType: "client" }));
                    }}
                >
                    <FormattedMessage id="DashboardConsultant.findGig" />
                </Button>
            </div>
        </div>
    )
}
export default DashboardConsultant